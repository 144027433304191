import React, { useContext, useReducer, useState } from 'react';
import { AuthContext } from '../../App';
import { Redirect } from "react-router-dom";
import HideNav from '../Auth/HideNav'
import axios from 'axios';
import { Spin, Switch, Alert } from 'antd';
import url from '../main/url';




const SignIn = () => {
    const [spin, setSpin] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')


    const context = useContext(AuthContext)

    const [success, setSuccess] = useState(false)




    const login = () => {
        // setSuccess(true)
        setError('')

        if (email == '' || password == '') {
            setError('All fields are required');
            return
        }


        axios.post(url + 'manager/auth/login', { email, password })
            .then(res => {

                console.log('res')
                console.log(res)

                if (res.data.success) {
                    localStorage.setItem('token', res.data.result.accessToken);
                    context.authDispatch('true')
                    setSuccess(true)
                } else {
                    setError('Wrong username or password')
                }


            }).catch(e => {
                // console.log(e)
                // setError(e.message)
                setError('Wrong username or password')

            }).then(r => {
                setSpin(false)
            })


    }
    return (<>

        {success ? (<>
            <Redirect to="/dashboard" />

        </>) : (<></>)}
        <div className="container">
            <HideNav />
            <Spin spinning={spin}></Spin>

            <div className="row">
                <div className="col-sm-4" />
                <div className="col-sm-4" style={{ paddingTop: '50px' }}>
                    <div className="row">
                        <div className="col-sm-12">
                            {error}
                        </div>
                    </div>
                    <h2 style={{ color: 'white' }}>Signin</h2>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" placeholder="Email address" value={email}
                            onChange={(e) => setEmail(e.target.value)} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" placeholder="Password" value={password}
                            onChange={(e) => setPassword(e.target.value)} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label></label>  <br />
                        <input type="submit" className="btn btn-primary" value="Sign In" onClick={login} />
                    </div>

                </div>
                <div className="col-sm-3" />
            </div>
        </div>
    </>);
}

export default SignIn;