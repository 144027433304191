import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../App';
const HideNav = () => {
    const context = useContext(AuthContext)
    useEffect(() => {
        context.authDispatch('false')

    }, [])
    return (<></>);
}

export default HideNav;