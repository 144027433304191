import React, { useEffect, useState } from 'react';
import EukaDataTable from "euka-datatables";
import ShowNav from '../Auth/ShowNav';
import { Modal, Button } from 'antd';
import axios from 'axios';
import url from './url';
import moment from 'moment'
let columns = [
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'created_at',
    label: 'Date',
    options: {//column options
      customCellRender: (value, record, metadata) => {
        //the initial 'value' before customization
        //the actual 'record' from the table data
        //const {isFooterRow, label, parentRecord, isCollapsed} = metadata; //the metadata props. The 'parentRecord' is an object only defined for the child table customCellRender.
        return moment(value).format('YYYY-MM-DD h:mm')

      }
    }
  }
]

let options = {
  responsive: 'collapse'
};
const Groups = () => {
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    var token = localStorage.getItem('token', false)
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(url + 'admin/groups', config)
      .then(res => {
        console.log(res)
        setGroups(res.data.result.data)

      }).catch(err => {

      }).then(r => {

      })

  }, [])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    var token = localStorage.getItem('token', false)
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    var postData = {
      name: group,
      user_id: 1

    };

    axios.post(url + 'admin/create/group', postData, config)
      .then(res => {
        console.log(res)

      }).catch(e => {
        console.log(e)

      }).then(r => {

      })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (<div className="container">
    <ShowNav />
    <h4 style={{ color: 'white' }}>Groups</h4>
    <div className="row">
      <div className="col-sm-12 float-end">
        <Button type="primary" onClick={showModal}>
          Add Group
        </Button>
      </div>
    </div>
    <Modal title="Add New Group" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="Close" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="Add Group" type="primary" onClick={handleOk}>
          Add group
        </Button>,

      ]}

    >
      <p>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>
                Name
              </label>
              <input type="text" className="form-control" onChange={(e) => setGroup(e.target.value)} value={group} />
            </div>
          </div>
        </div>
      </p>

    </Modal>
    <EukaDataTable
      key={'table-1'}
      title={'Groups'}
      columns={columns}
      data={groups}
      options={options}
    />
  </div>);
}

export default Groups;