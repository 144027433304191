import React from 'react';
import ShowNav from '../Auth/ShowNav'
const Dashboard = () => {
  return (<>
    <ShowNav />
    <br />
    <br />
    <h3 style={{ color: 'white' }}>Quick Links</h3>
    <div class="row" style={{ paddingTop: '10x', paddingLeft: '10x' }}>
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Users</h5>
            <p class="card-text">Total: 10</p>
            <a href="#" class="btn btn-primary">View Users</a>
          </div>
        </div>
      </div>
      {/* <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Groups</h5>
            <p class="card-text">1</p>
            <a href="#" class="btn btn-primary">View Groups</a>
          </div>
        </div>
      </div> */}
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Today's Scannings</h5>
            <p class="card-text">  20</p>
            <a href="#" class="btn btn-primary">View Scanning</a>
          </div>
        </div>
      </div>
      {/* <div class="col-sm-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Total Scannings</h5>
        <p class="card-text"> 2</p>
        <a href="#" class="btn btn-primary">View Scannings</a>
      </div>
    </div>
  </div> */}
    </div>
  </>);
}

export default Dashboard;