import React from "react";
import { Route, Redirect } from "react-router-dom";
import ShowNav from "./ShowNav";
// import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  // const authTokens = false;
  var authTokens=sessionStorage.getItem('myData',false);
  // console.log(authTokens);

  
  
  

    return (
        <>
            <ShowNav />
    <Route
      {...rest}
      render={props =>
        authTokens ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
            />
            </>
  );
}

export default PrivateRoute;