import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { Redirect } from "react-router-dom";

const Logout = () => {
    const context = useContext(AuthContext)
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        logout();



    }, [])

    const logout = () => {
        context.authDispatch('false')
        setSuccess(true)


    }
    return (<>

        {success ? (<>
            <Redirect to="/" />

        </>) : (<> Waiting...</>)}
    </>);
}

export default Logout;