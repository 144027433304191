import React, { useContext, useState } from 'react';
import { Layout, Menu } from 'antd';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Dashboard from './Dashboard';
import Users from './Users';
import SignIn from '../Auth/SignIn';
import Devices from './Devices';
import SideBar from './SideBar';
import { AuthContext } from '../../App';
import Logout from '../Auth/Logout';
import Verify from './usermgt/Vefify';
import UpdateStatus from './usermgt/UpdateStatus';
import PrivateRoute from '../Auth/PrivateRoute';
import Groups from './Groups';
import MailRecepients from './MailRecepients';
import Time from './Time';

// import Background from "../../assets/"

const { Header, Content, Footer, Sider } = Layout;
const logo = require('../../assets/bg.jpg')


const Nav = () => {
    // state = {
    //     collapsed: false,
    // };


    const [collapsed, setCollapsed] = useState(false)
    const context = useContext(AuthContext)

    console.log('context')
    console.log(context)

    const toggle = () => {
        // this.setState({
        //     collapsed: !this.state.collapsed,
        // });
        setCollapsed({ collapsed: !collapsed })


    };


    return (
        <Router>
            <Layout>

                {context.authstate ? <>
                    <SideBar />

                </> : <></>}


                <Layout>
                    <Header style={{ padding: 0 }} style={{ background: '#28696d' }} />
                    <Content
                        className="body"
                    // style={{
                    //     margin: '24px 16px',
                    //     padding: 24,
                    //     minHeight: 280,
                    // }}
                    >
                        <Switch>
                            
                            <Route path="/dashboard">
                                <Dashboard />
                            </Route>
                            <Route path="/users">
                                <Users />
                            </Route>
                            <Route path="/groups">
                                <Groups />
                            </Route>
                            <Route path="/recipient">
                                <MailRecepients />
                            </Route>
                            <PrivateRoute path="/notification-time">
                                <Time />
                            </PrivateRoute>
                            <PrivateRoute path="/updatestatus">
                                <UpdateStatus />
                            </PrivateRoute>
                            <Route path="/logout">
                                <Logout />
                            </Route>
                            <Route path="/">
                                <SignIn />
                            </Route>
                        </Switch>
                    </Content>
                    {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
                </Layout>
            </Layout>
        </Router>
    );
}


export default Nav;