// import logo from './logo.svg';
import React from 'react';
import { useEffect, useReducer } from 'react';
import './App.css';
import Nav from './components/main/Nav2';

export const AuthContext = React.createContext();

const initialauthstate = false;

const authreducer = (state, action) => {
  switch (action) {

    case 'true':
      return true;
    case 'false':
      return false
    default:
      return state
  }
};

function App() {
  const [authstate, authDispatch] = useReducer(authreducer, initialauthstate)
  return (
    <AuthContext.Provider value={{ authstate: authstate, authDispatch: authDispatch }}>
      <Nav />


    </AuthContext.Provider>
  );
}

export default App;
