import React from 'react';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';


import { Layout, Menu } from 'antd';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
const { SubMenu } = Menu;

const SideBar = () => {
    const { Header, Content, Footer, Sider } = Layout;

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
                console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
            style={{ background: '#28696d', height: '100vh' }}
        >
            <div className="logo" />
            <img src="/assets/docsun.png" alt="Logo" height="60" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} style={{ background: '#28696d', height: '100vh' }}>
                <Menu.Item key="1" icon={<UserOutlined />} >
                    <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>
                {/* <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                    <Link to="/users">Users</Link>
                </Menu.Item> */}
                {/* <Menu.Item key="32" icon={<VideoCameraOutlined />}>
                    <Link to="/diagnosis">Diagnosis</Link>
                </Menu.Item> */}
                <Menu.Item key="3" icon={<UserOutlined />}>
                    <Link to="/users">Users</Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<VideoCameraOutlined />}>
                    <Link to="/groups">Groups</Link>
                </Menu.Item>
                        <SubMenu key="sub1" icon={<UserOutlined />} title="Settings">
                        <Menu.Item key="31">  <Link to="/recipient">Recipient</Link></Menu.Item>
                        <Menu.Item key="32"> <Link to="/notification-time">Notification Time</Link></Menu.Item>
                        <Menu.Item key="33"><Link to="/time">Time </Link></Menu.Item>
                        </SubMenu>
                <Menu.Item key="5" icon={<UploadOutlined />}>

                    <Link to="/logout">Logout</Link>

                </Menu.Item>
                {/* <Menu.Item key="4" icon={<UserOutlined />}>
                            nav 4
                        </Menu.Item> */}
            </Menu>
        </Sider>

    );
}

export default SideBar;