import React, { useState } from 'react';
import EukaDataTable from "euka-datatables";
import ShowNav from '../Auth/ShowNav';
import { Modal, Button } from 'antd';
    let columns = [
    {
        name:'name',
        label:'Name'
    },
    {
        name:'email',
        label:'Email'
    },
    {
        name:'date',
        label:'Date'
        },
    ]

    let options = {
    responsive:'collapse'
};
const Users = () => {
    const [groups, setGroups] = useState([{ name: 'Students','email':'josphat@mailinator.com', date: '2021-08-06' }])
     const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
    return (<div className="container">
        <ShowNav />
        <h4 style={{ color: 'white' }}>Users</h4>
        <div className="row">
            <div className="col-sm-12 float-end">
                <Button type="primary" onClick={showModal}>
        Add User
      </Button>
            </div>
             </div>
        <Modal title="Add New User" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                      footer={[
            <Button key="Close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="Add Group" type="primary"  onClick={handleOk}>
              Send Invite
            </Button>,
           
          ]}
        
        >
            <p>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>
                                Name
                            </label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>
                                Email
                            </label>
                            <input type="email" className="form-control" />
                        </div>
                    </div>
                </div>
        </p>
        
      </Modal>
                <EukaDataTable
    key={'table-1'}
    title={'Groups'}
    columns={columns}
    data={groups}
    options={options}
/>
    </div>);
}
 
export default Users;